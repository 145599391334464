import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/auth";
import { Container } from "./styles";
import UserService from "../../services/UserService";

export default function Footer() {
  const authContext = useContext(AuthContext);
  const token = authContext.user?.token;
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    async function fetchUserData() {
      if (token) {
        try {
          const response = await UserService.checkUser(token);
          if (response) {
            setUserData(response);
          }
        } catch (error) {}
      }
    }

    fetchUserData();
  }, [token]);

  if (userData) {
    return (
      <Container>
        Sessão iniciada para: {userData.condo_name} - {userData.user_name}
      </Container>
    );
  } else {
    return (
      <Container>
        MyPostal Gestão de Encomendas - Todos os direitos reservados.
      </Container>
    );
  }
}
