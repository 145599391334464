import { useEffect, useState, useRef, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";

import PageHeaderContact from "../../components/PageHeaderContact";
import PackageForm from "../../components/PackageForm";
import Loader from "../../components/Loader";
import ContactService from "../../services/ContactService";

import PackagesService from "../../services/PackagesService";
import { AuthContext } from "../../contexts/auth";

import toast from "../../utils/toast";
import useSafeAsyncAction from "../../hooks/useSafeAsyncAction";

export default function NewPackage() {
  const authContext = useContext(AuthContext);
  const token = authContext.user?.token;

  const { id } = useParams();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [contactName, setContactName] = useState("");

  const packageFormRef = useRef(null);

  const safeAsyncAction = useSafeAsyncAction();

  useEffect(() => {
    async function loadContact() {
      try {
        const idContact = await ContactService.getContactById(id, token);

        safeAsyncAction(() => {
          packageFormRef.current.setFieldsValues(idContact);
          setIsLoading(false);
          setContactName(idContact.name);
        });
      } catch (error) {
        console.error("loadContact Error:", error);

        safeAsyncAction(() => {
          history.push("/newpackage");
          toast({
            type: "danger",
            text: "Contato não encontrado!",
          });
        });
      }
    }

    loadContact();
  }, [id, history, safeAsyncAction, token]);

  async function handleSubmit(formData) {
    try {
      const categoryId = formData.categoryId;
      const categoryPackage = categoryId !== "" ? categoryId : null;

      const myPackage = {
        package_number: formData.packageNumber,
        name: formData.name,
        address: formData.address,
        complement: formData.complement,
        category_package: categoryPackage,
      };

      await PackagesService.createPackage(myPackage, token);

      setContactName(formData.name);

      toast({
        type: "success",
        text: "Encomenda cadastrada com sucesso!",
        duration: 3000,
      });

      // Navegar para a rota /newpackage após cadastrar a encomenda
      history.push("/newpackage");
    } catch {
      toast({
        type: "danger",
        text: "Ocorreu um erro ao cadastrar uma encomenda",
        duration: 4000,
      });
    }
  }

  return (
    <>
      <Loader isLoading={isLoading} />

      <PageHeaderContact
        title={
          isLoading ? "Carregando..." : `Cadastrar encomenda de ${contactName}`
        }
      />
      <PackageForm
        ref={packageFormRef}
        onSubmit={handleSubmit}
        buttonLabel="Cadastrar encomenda"
      />
    </>
  );
}
