import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Sora', sans-serif;
 
}

body{
  background-color: ${({ theme }) => theme.colors.background};
  font-size:18px;
  color: ${({ theme }) => theme.gray[900]}
}

button{
  cursor: pointer;
}

`;
