import PropTypes from "prop-types";

import { Overlay, Container, Footer } from "./styles";
import Button from "../Button";
import ReactPortal from "../ReactPortal";

export default function Modal({
  success,
  title,
  visible,
  isLoading,

  children,
  cancelLabel,
  confirmLabel,
  onCancel,
  onConfirm,
}) {
  if (!visible) {
    return null;
  }

  return (
    <ReactPortal containerId="modal-root">
      <Overlay>
        <Container success={success}>
          <h1>{title}</h1>

          <div className="modal-body">{children}</div>

          <Footer>
            <button
              title="Confirmar a entrega"
              type="button"
              className="cancel-button"
              onClick={onCancel}
              disabled={isLoading}
            >
              {cancelLabel}
            </button>
            <Button
              success={success}
              type="button"
              title="Cancelar a ação"
              onClick={onConfirm}
              isLoading={isLoading}
            >
              {confirmLabel}
            </Button>
          </Footer>
        </Container>
      </Overlay>
      ,
    </ReactPortal>
  );
}

Modal.propTypes = {
  success: PropTypes.bool,
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  children: PropTypes.node.isRequired,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  success: false,
  isLoading: false,
  cancelLabel: "Cancelar",
  confirmLabel: "Confirmar",
};
