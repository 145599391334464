import Httpclient from "./utils/Httpclient";
import urlDev from "./urlFetch";

class UserService {
  constructor() {
    this.Httpclient = new Httpclient(urlDev);
  }

  checkUser(token) {
    return this.Httpclient.get(`/users/checkuser`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export default new UserService();
