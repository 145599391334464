import { Link } from "react-router-dom";
import { useEffect, useState, useMemo, useCallback, useContext } from "react";

import {
  Container,
  Header,
  ListHeader,
  Card,
  InputSearchContainer,
  ErrorContainer,
  EmptyListContainer,
  SearchNotFoundContainer,
} from "./styles";

import { AuthContext } from "../../contexts/auth";

import arrow from "../../assets/images/icons/arrow.svg";
import addPack from "../../assets/images/icons/add-icon.svg";
import sad from "../../assets/images/sad.svg";
import emptyUser from "../../assets/images/empty-user.svg";
import magnifierQuestion from "../../assets/images/magnifier-question.svg";

import PageHeader from "../../components/PageHeader";

import ContactService from "../../services/ContactService";
import Loader from "../../components/Loader";
import Button from "../../components/Button";

export default function NewPackages() {
  const authContext = useContext(AuthContext);
  const token = authContext.user?.token;

  const [contacts, setContacts] = useState([]);
  const [orderBy, setOrderBy] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const filteredContacts = useMemo(
    () =>
      contacts.filter((contact) =>
        contact.name.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [contacts, searchTerm]
  );

  const loadContacts = useCallback(async () => {
    try {
      setIsLoading(true);

      const contactsList = await ContactService.listContacts(orderBy, token);

      setHasError(false);
      setContacts(contactsList);
    } catch {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }, [orderBy, token]);

  useEffect(() => {
    loadContacts();
  }, [loadContacts]);

  function handleToggleOrderBy() {
    setOrderBy((prevState) => (prevState === "asc" ? "desc" : "asc"));
  }

  function handleChangeSearchTerm(event) {
    setSearchTerm(event.target.value);
  }

  function handleTryAgain() {
    loadContacts();
  }

  return (
    <Container>
      <Loader isLoading={isLoading} />

      <PageHeader title="" />

      {contacts.length > 0 && (
        <InputSearchContainer>
          <input
            value={searchTerm}
            type="text"
            placeholder="Pesquise pelo nome"
            onChange={handleChangeSearchTerm}
          />
        </InputSearchContainer>
      )}

      <Header
        justifyContent={
          hasError
            ? "flex-end"
            : contacts.length > 0
            ? "space-between"
            : "space-between"
        }
      >
        {!hasError && contacts.length > 0 ? (
          <strong>
            {filteredContacts.length}
            {filteredContacts.length === 1
              ? " usuário cadastrado"
              : " usuários cadastrados"}
          </strong>
        ) : null}

        <Link title="Cadastrar uma nova encomenda" to="/contact">
          Novo usuário
        </Link>
      </Header>

      {hasError && (
        <ErrorContainer>
          <img src={sad} alt="Erro =(" />
          <div className="details">
            <strong> Ocorreu um erro ao listar os contatos!</strong>
            <Button onClick={handleTryAgain} type="button">
              Tentar novamente
            </Button>
          </div>
        </ErrorContainer>
      )}

      {!hasError && (
        <>
          {contacts.length < 1 && !isLoading && (
            <EmptyListContainer>
              <img src={emptyUser} alt="Empty box" />

              <p>
                Você não tem nenhum usuário cadastrado! Clique no botão
                <strong> "Novo usuário"</strong> à cima para cadastrar.
              </p>
            </EmptyListContainer>
          )}

          {contacts.length > 0 && filteredContacts.length < 1 && (
            <SearchNotFoundContainer>
              <img src={magnifierQuestion} alt="magnifierQuestion" />

              <span>
                Nenhum usuário foi encontrado para
                <strong> {searchTerm} </strong>
                cadastre no botão acima
              </span>
            </SearchNotFoundContainer>
          )}

          {filteredContacts.length > 0 && (
            <ListHeader orderBy={orderBy}>
              <button type="button" onClick={handleToggleOrderBy}>
                <span type="button">Nome</span>
                <img src={arrow} alt="Arrow" />
              </button>
            </ListHeader>
          )}

          {filteredContacts
            .filter((contacts) => contacts.billing)
            .map((contacts) => {
              return (
                <Card key={contacts._id}>
                  <div className="info">
                    <div className="contact-name">
                      <strong>{contacts.name}</strong>
                      {contacts.name && <small>{contacts.name}</small>}
                    </div>
                    <span>{contacts.adress}</span>
                    <span>{contacts.complement}</span>
                    <span>{contacts.phone}</span>
                  </div>
                  <div className="actions">
                    <Link
                      title="Cadastrar uma nova encomenda"
                      to={`/new/${contacts._id}`}
                    >
                      <img src={addPack} alt="package" />
                    </Link>
                  </div>
                </Card>
              );
            })}
        </>
      )}
    </Container>
  );
}
