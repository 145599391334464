//import { Switch, Route } from "react-router-dom";

import { Switch } from "react-router-dom";

import SignIn from "./pages/SignIn";
import Home from "./pages/Home";
import New from "./pages/NewPkg";
import NewPackages from "./pages/NewPackages";
import NewContact from "./pages/NewContact";
import EditPackage from "./pages/EditPackage";
import NotFound from "./pages/NotFound";

import Private from "./Private";

export default function Routes() {
  return (
    <Switch>
      <Private exact path="/" component={SignIn} />
      <Private exact path="/home" component={Home} isPrivate />
      <Private exact path="/newpackage" component={NewPackages} isPrivate />
      <Private exact path="/new/:id" component={New} isPrivate />
      <Private exact path="/contact" component={NewContact} isPrivate />
      <Private exact path="/edit/:id" component={EditPackage} isPrivate />
      <Private component={NotFound} />
    </Switch>
  );
}

//  <Private exact path="/new" component={New} isPrivate />
