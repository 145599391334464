import Httpclient from "./utils/Httpclient";
import urlDev from "./urlFetch";

class ContactsService {
  constructor() {
    this.Httpclient = new Httpclient(urlDev);
  }

  listContacts(orderBy = "asc", token) {
    return this.Httpclient.get(`/contacts?order=${orderBy}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  getContactById(id, token) {
    return this.Httpclient.get(`/contacts/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  createContact(contactdata, token) {
    return this.Httpclient.post("/contacts", {
      body: contactdata,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  updateContact(_id, contactdata, token) {
    return this.Httpclient.put(
      `/manualpackages/${_id}`,
      {
        body: contactdata,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }
}

export default new ContactsService();
