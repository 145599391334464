//URLS PARA RODAR A APLICAÇÃO

//export const urlAPI = "https://mypostal-api-test.cyclic.app"
//export default urlAPI;

//const urlDev = "http://localhost:3333";
//export default urlDev;

const urlDev = "https://mypostal-api-test.cyclic.app";
export default urlDev;
