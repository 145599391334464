/**
import axios from "axios";
import { parseCookies } from "nookies";
import { AuthTokenError } from "../errors/AuthTokenError";
import { signOut } from "../contexts/auth";

export function setupAPIClient(context) {
  let cookies = parseCookies(context);

  const api = axios.create({
    baseURL: "https://mypostal-api-test.cyclic.app",
    headers: {
      Authorization: `Bearer ${cookies["@nextauth.token"]}`,
    },
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        if (typeof window !== undefined) {
          signOut();
        } else {
          return Promise.reject(new AuthTokenError());
        }
      }

      return Promise.reject(error);
    }
  );

  return api;
}

*/

// api.js
import axios from "axios";
import urlDev from "./urlFetch"; // Note a mudança aqui

export function setupAPIClient(token) {
  const api = axios.create({
    baseURL: urlDev,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return api;
}
