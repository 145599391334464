import { Link } from "react-router-dom";
import { useEffect, useState, useMemo, useCallback, useContext } from "react";

import {
  Container,
  Header,
  ListHeader,
  Card,
  InputSearchContainer,
  ErrorContainer,
  EmptyListContainer,
  SearchNotFoundContainer,
} from "./styles";

import arrow from "../../assets/images/icons/arrow.svg";
import done from "../../assets/images/icons/done.svg";
import edit from "../../assets/images/icons/edit.svg";
//import trash from "../../assets/images/icons/trash.svg";
import sad from "../../assets/images/sad.svg";
import emptyBox from "../../assets/images/empty-box.svg";
import magnifierQuestion from "../../assets/images/magnifier-question.svg";

import { AuthContext } from "../../contexts/auth";

import PackagesService from "../../services/PackagesService";
import UserService from "../../services/UserService";
import Loader from "../../components/Loader";
import Button from "../../components/Button";
import Modal from "../../components/Modal";

import toast from "../../utils/toast";

export default function Home() {
  const authContext = useContext(AuthContext);
  const token = authContext.user?.token;
  const [userData, setUserData] = useState(null);

  const [packages, setPackages] = useState([]);

  const [orderBy, setOrderBy] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [isDeliveryModalVisible, setIsDeliveryModalVisible] = useState(false);
  const [packageBeingDelivery, setPackageBeingDelivery] = useState(null);
  const [isLoadingDelivery, setIsLoadingDelivery] = useState(false);

  useEffect(() => {
    async function fetchUserData() {
      if (token) {
        try {
          const response = await UserService.checkUser(token);
          if (response) {
            setUserData(response);
          }
        } catch (error) {}
      }
    }

    fetchUserData();
  }, [token]);

  const filteredPackages = useMemo(
    () =>
      packages.filter((pkg) =>
        pkg.name.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [packages, searchTerm]
  );

  const loadPackages = useCallback(async () => {
    try {
      setIsLoading(true);

      const packagesList = await PackagesService.listPackages(orderBy, token);

      setHasError(false);
      setPackages(packagesList);
    } catch {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  }, [orderBy, token]);

  useEffect(() => {
    loadPackages();
  }, [loadPackages]);

  function handleToggleOrderBy() {
    setOrderBy((prevState) => (prevState === "asc" ? "desc" : "asc"));
  }

  function handleChangeSearchTerm(event) {
    setSearchTerm(event.target.value);
  }

  function handleTryAgain() {
    loadPackages();
  }

  function handleDeliveryPackage(pkg) {
    const updatedPackage = {
      ...pkg,
      delivery: true,
    };
    setPackageBeingDelivery(updatedPackage);
    setIsDeliveryModalVisible(true);
  }

  function handleCloseDeliveryModal() {
    setIsDeliveryModalVisible(false);
    setPackageBeingDelivery(null);
  }

  async function handleConfirmDeliveryPackage() {
    try {
      setIsLoadingDelivery(true);
      const _id = packageBeingDelivery._id;

      const updatedPackage = {
        ...packageBeingDelivery,
        delivery: true,
      };

      await PackagesService.ConfirmPackageDelivery(_id, updatedPackage, token);

      setPackages((prevState) =>
        prevState.filter((pkg) => pkg._id !== packageBeingDelivery._id)
      );
      handleCloseDeliveryModal();
      toast({
        type: "success",
        text: "Encomenda entregue com sucesso!",
        duration: 3000,
      });
    } catch {
      toast({
        type: "danger",
        text: "Ocorreu um erro ao entregar a encomenda",
        duration: 4000,
      });
    } finally {
      setIsLoadingDelivery(false);
    }
  }

  return (
    <Container>
      <Loader isLoading={isLoading} />

      <Modal
        success
        isLoading={isLoadingDelivery}
        visible={isDeliveryModalVisible}
        title={`Deseja entregar a encomenda de "${packageBeingDelivery?.name}"?`}
        confirmLabel="Entregar"
        onCancel={handleCloseDeliveryModal}
        onConfirm={handleConfirmDeliveryPackage}
      >
        <p>A ação não pode ser desfeita</p>
      </Modal>

      {packages.length > 0 && (
        <InputSearchContainer>
          <input
            value={searchTerm}
            type="text"
            placeholder="Pesquise pelo nome do morador"
            onChange={handleChangeSearchTerm}
          />
        </InputSearchContainer>
      )}

      <Header
        justifyContent={
          hasError
            ? "flex-end"
            : packages.length > 0
            ? "space-between"
            : "space-between"
        }
      >
        {!hasError && packages.length > 0 ? (
          <strong>
            {filteredPackages.length}
            {filteredPackages.length === 1 ? " encomenda" : " encomendas"}
          </strong>
        ) : null}

        {userData &&
        (userData.user_type === "master" ||
          userData.user_type === "operacional") ? (
          <Link title="Cadastrar uma nova encomenda" to="/newpackage">
            Nova Encomenda
          </Link>
        ) : null}
      </Header>

      {hasError && (
        <ErrorContainer>
          <img src={sad} alt="Erro =(" />
          <div className="details">
            <strong> Ocorreu um erro ao listar as encomendas!</strong>
            <Button onClick={handleTryAgain} type="button">
              Tentar novamente
            </Button>
          </div>
        </ErrorContainer>
      )}

      {!hasError && (
        <>
          {packages.length < 1 && !isLoading && (
            <EmptyListContainer>
              <img src={emptyBox} alt="Empty box" />

              {userData &&
              (userData.user_type === "master" ||
                userData.user_type === "operacional") ? (
                <p>
                  Você não tem nenhuma encomenda cadastrada! Clique no botão
                  <strong> "Nova Encomenda"</strong> à cima para cadastrar uma.
                </p>
              ) : (
                <p>Você não tem nenhuma encomenda para entregar!</p>
              )}
            </EmptyListContainer>
          )}

          {packages.length > 0 && filteredPackages.length < 1 && (
            <SearchNotFoundContainer>
              <img src={magnifierQuestion} alt="magnifierQuestion" />

              <span>
                Nenhuma encomenda foi encontrada para
                <strong> {searchTerm}</strong>
              </span>
            </SearchNotFoundContainer>
          )}

          {filteredPackages.length > 0 && (
            <ListHeader orderBy={orderBy}>
              <button type="button" onClick={handleToggleOrderBy}>
                <span type="button">Nome</span>
                <img src={arrow} alt="Arrow" />
              </button>
            </ListHeader>
          )}

          {filteredPackages
            .filter((packages) => !packages.delivery) // Aplicar a condição packages.delivery === false
            .map((packages) => (
              <Card key={packages._id}>
                <div className="info">
                  <div className="contact-name">
                    <strong>{packages.name}</strong>
                    {packages.category_package && (
                      <small>{packages.category_package}</small>
                    )}
                  </div>
                  <span>{packages.address}</span>
                  <span>{packages.complement}</span>
                  <span>{packages.contact_phone}</span>
                </div>
                <div className="actions">
                  <Link
                    title="Editar dados da encomenda"
                    to={`/edit/${packages._id}`}
                  >
                    <img src={edit} alt="Editar dados" />
                  </Link>
                  <button
                    type="button"
                    title="Confirme a entrega da encomenda"
                    onClick={() => handleDeliveryPackage(packages)}
                  >
                    <img src={done} alt="Entregar" />
                  </button>
                </div>
              </Card>
            ))}
        </>
      )}
    </Container>
  );
}
