import { useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import toast from "../../utils/toast";
import PageHeaderContact from "../../components/PageHeaderContact";
import ContactForm from "../../components/ContactForm";
import ContactService from "../../services/ContactService";
import { AuthContext } from "../../contexts/auth";

export default function NewContact() {
  const authContext = useContext(AuthContext);
  const token = authContext.user?.token;

  const history = useHistory();
  const contactFormRef = useRef(null);

  async function handleSubmit(formData) {
    try {
      const myContact = {
        name: formData.name,
        address: formData.address,
        complement: formData.complement,
        email: formData.email,
        phone: formData.phone,
      };

      // Passar o token como segundo argumento
      await ContactService.createContact(myContact, token);

      history.push("/newpackage");

      toast({
        type: "success",
        text: "Usuário cadastrado com sucesso!",
        duration: 3000,
      });
    } catch (error) {
      toast({
        type: "danger",
        text: "Esse e-mail já está cadastrado",
        duration: 4000,
      });
    }
  }

  return (
    <>
      <PageHeaderContact title="Novo cliente da MyPostal" />
      <ContactForm
        ref={contactFormRef}
        buttonLabel="Cadastrar usuário"
        onSubmit={handleSubmit}
      />
    </>
  );
}
