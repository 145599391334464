import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../src/contexts/auth";

export default function Private({
  component: Component,
  isPrivate,
  userType,
  ...rest
}) {
  const { signed, loading, user } = useContext(AuthContext);

  if (loading) {
    return <div></div>;
  }

  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }

  if (signed && !isPrivate) {
    return <Redirect to="/home" />;
  }

  if (isPrivate && userType) {
    if (userType === "master" && user.user_type === "master") {
      return <Route {...rest} render={(props) => <Component {...props} />} />;
    }

    if (
      (userType === "operacional" || userType === "delivery") &&
      user.user_type !== "master"
    ) {
      return <Route {...rest} render={(props) => <Component {...props} />} />;
    }

    return <Redirect to="/home" />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
}

//export default function RouteWrapper({
