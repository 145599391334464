import styled from "styled-components";

export const Container = styled.div`
  margin-top: 10px;
  background: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  padding: 16px;
  border-radius: 4px;
  display: flex;
  align-items: center; /* Centraliza verticalmente o conteúdo */
  justify-content: center; /* Centraliza horizontalmente o conteúdo */

  span {
    font-size: 14px;
    color: ${({ theme }) => theme.gray[200]};
  }

  .my {
    color: #c50dd9;
    font-weight: bold;
  }

  .postal {
    color: #1635bf;
    font-weight: bold;
  }

  a {
    color: black; /* Define a cor preta para o link */
    text-decoration: none; /* Remove o sublinhado do link */
    transition: color 0.2s; /* Adiciona uma transição suave para a cor */
    font-weight: bold;

    &:hover {
      color: #808080; /* Muda a cor quando o link é hover */
    }
  }
`;
