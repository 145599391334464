import GlobalStyles from "../../assets/styles/global";

import { ThemeProvider } from "styled-components";
import { BrowserRouter } from "react-router-dom";
import defaultTheme from "../../assets/styles/themes/default";

import { Container } from "./styles";

import Header from "../Header";
import Footer from "../Footer";
import Routes from "../../Routes";
import ToastContainer from "../Toast/ToastContainer";
import AuthProvider from "../../contexts/auth";

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={defaultTheme}>
        <AuthProvider>
          <GlobalStyles />
          <ToastContainer />
          <Container>
            <Header />
            <Routes />
            <Footer />
          </Container>
        </AuthProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
