//import { setupAPIClient } from "./api";

//export const api = setupAPIClient();

// apiClient.js
import { setupAPIClient } from "./api";

const token = localStorage.getItem("@nextauth.token");

export const api = setupAPIClient(token);
