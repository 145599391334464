//campo telefone - tudo comentado o que precisa
import { useState, forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import useErrors from "../../hooks/useErrors";

import isEmailValid from "../../utils/isEmailValid";
import formatPhone from "../../utils/formatPhone";
import isAddressValid from "../../utils/isAddressValid";

import { Form, ButtonContainer } from "./styles";

import FormGroup from "../FormGroup";
import Input from "../Input";
import Button from "../Button";

const ContactForm = forwardRef(({ buttonLabel, onSubmit }, ref) => {
  //

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [complement, setComplement] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { errors, setError, removeError, getErrorMessageByFieldName } =
    useErrors();

  const isFormValid =
    name.trim() &&
    address.trim() &&
    email.trim() &&
    phone.trim() &&
    errors.length === 0;

  useImperativeHandle(
    ref,

    () => ({
      setFieldsValues: (contactData) => {
        setName(contactData.name ?? "");
        setAddress(contactData.address ?? "");
        setComplement(contactData.complement ?? "");
        setEmail(contactData.email ?? "");
        setPhone(contactData.phone ?? "");
      },
      resetFields: () => {
        setName("");
        setAddress("");
        setComplement("");
        setEmail("");
        setPhone("");
      },
    }),
    []
  );

  function handleNameChange(event) {
    setName(event.target.value);

    if (!event.target.value) {
      setError({
        field: "name",
        message: "Nome é obrigatório",
      });
    } else {
      removeError("name");
    }
  }

  function handleAddressChange(event) {
    setAddress(event.target.value);

    //  if (event.target.value && !isEmailValid(event.target.value)) {
    if (event.target.value && !isAddressValid(event.target.value)) {
      setError({
        field: "address",
        message:
          "Logradouro com número deve ser separado por vírgula (ex: Rua Alagoas, 12)",
      });
    } else {
      removeError("address");
    }
  }

  function handleEmailChange(event) {
    const emailValue = event.target.value;
    setEmail(emailValue);

    if (!emailValue) {
      setError({
        field: "email",
        message: "E-mail é obrigatório",
      });
    } else if (emailValue && !isEmailValid(emailValue)) {
      setError({
        field: "email",
        message: "E-mail válido é obrigatório",
      });
    } else {
      removeError("email");
    }
  }

  function handlePhoneChange(event) {
    const formattedPhone = formatPhone(event.target.value); // Formata o telefone

    setPhone(formattedPhone);

    if (!formattedPhone) {
      setError({
        field: "phone",
        message: "Número para contato é obrigatório",
      });
    } else {
      removeError("phone");
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsSubmitting(true);

    const formattedPhone = phone.replace(/\D/g, "");

    await onSubmit({
      name,
      address,
      complement,
      email,
      phone: formattedPhone,
    });

    setIsSubmitting(false);
  }

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <FormGroup error={getErrorMessageByFieldName("name")}>
        <Input
          error={getErrorMessageByFieldName("name")}
          value={name}
          onChange={handleNameChange}
          placeholder="Nome*"
          disabled={isSubmitting}
        />
      </FormGroup>
      <FormGroup error={getErrorMessageByFieldName("address")}>
        <Input
          error={getErrorMessageByFieldName("address")}
          value={address}
          onChange={handleAddressChange}
          placeholder="Endereço (ex: Rua Quinze, 12)*"
          disabled={isSubmitting}
        />
      </FormGroup>
      <FormGroup>
        <Input
          value={complement}
          onChange={(event) => setComplement(event.target.value)}
          placeholder="Complemento (ex: Bloco 1, apto 1120)"
          disabled={isSubmitting}
        />
      </FormGroup>

      <FormGroup error={getErrorMessageByFieldName("email")}>
        <Input
          value={email}
          error={getErrorMessageByFieldName("email")}
          onChange={handleEmailChange}
          placeholder="E-mail*"
          disabled={isSubmitting}
        />
      </FormGroup>

      <FormGroup error={getErrorMessageByFieldName("phone")}>
        <Input
          type="tel"
          value={phone}
          error={getErrorMessageByFieldName("phone")}
          onChange={handlePhoneChange}
          placeholder="Nº telefone (somente números)*"
          maxLength={15}
        />
      </FormGroup>

      <ButtonContainer>
        <Button type="submit" disabled={!isFormValid} isLoading={isSubmitting}>
          {buttonLabel}
        </Button>
      </ButtonContainer>
    </Form>
  );
});

ContactForm.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ContactForm;
