//campo telefone - tudo comentado o que precisa
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import useErrors from "../../hooks/useErrors";
import useSafeAsyncState from "../../hooks/useSafeAsyncState";
//import isEmailValid from "../../utils/isEmailValid";
//import formatPhone from "../../utils/formatPhone";
import isAddressValid from "../../utils/isAddressValid";
import CategoriesService from "../../services/CategoriesService";
import { Form, ButtonContainer } from "./styles";
import FormGroup from "../FormGroup";
import Input from "../Input";
import Select from "../Select";
import Button from "../Button";

const PackageForm = forwardRef(({ buttonLabel, onSubmit }, ref) => {
  //
  const [packageNumber, setPackageNumber] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [complement, setComplement] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [categories, setCategories] = useSafeAsyncState([]);
  const [isLoadingCategories, setIsLoadingCategories] = useSafeAsyncState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  //const [phone, setPhone] = useState("");
  //
  const { errors, setError, removeError, getErrorMessageByFieldName } =
    useErrors();
  useEffect(() => {
    async function loadCategories() {
      setIsLoadingCategories(true);
      try {
        const categoriesList = await CategoriesService.listCategories();
        setCategories(categoriesList);
      } catch {
      } finally {
        setIsLoadingCategories(false);
      }
    }
    loadCategories();
  }, [setCategories, setIsLoadingCategories]);
  const isFormValid = name && packageNumber && address && errors.length === 0;

  useImperativeHandle(
    ref,

    () => ({
      setFieldsValues: (packageData) => {
        setPackageNumber(packageData.package_number ?? "");
        setName(packageData.name ?? "");
        setAddress(packageData.address ?? "");
        setComplement(packageData.complement ?? "");
        setCategoryId(packageData.category_package ?? "");
      },
      resetFields: () => {
        setPackageNumber("");
        setName("");
        setAddress("");
        setComplement("");
        setCategoryId("");
      },
    }),
    []
  );
  function handlePackageNumberChange(event) {
    setPackageNumber(event.target.value);
    if (!event.target.value) {
      setError({
        field: "packageNumber",
        message: "Número da encomenda é obrigatório",
      });
    } else {
      removeError("packageNumber");
    }
  }
  function handleNameChange(event) {
    setName(event.target.value);
    if (!event.target.value) {
      setError({
        field: "name",
        message: "Nome é obrigatório",
      });
    } else {
      removeError("name");
    }
  }
  function handleAddressChange(event) {
    setAddress(event.target.value);
    //  if (event.target.value && !isEmailValid(event.target.value)) {
    if (event.target.value && !isAddressValid(event.target.value)) {
      setError({
        field: "address",
        message:
          "Obrigatório logradouro e número separado por vírgula (ex: Rua Alagoas, 12)",
      });
    } else {
      removeError("address");
    }
  }
  // function handlePhoneChange(event) {
  // setPhone(formatPhone(event.target.value));
  // }
  async function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);
    await onSubmit({
      packageNumber,
      name,
      address,
      complement,
      categoryId,
    });
    setIsSubmitting(false);
  }
  return (
    <Form onSubmit={handleSubmit} noValidate>
      <FormGroup error={getErrorMessageByFieldName("package_number")}>
        <Input
          error={getErrorMessageByFieldName("package_number")}
          value={packageNumber}
          onChange={handlePackageNumberChange}
          placeholder="Número da encomenda*"
          disabled={isSubmitting}
        />
      </FormGroup>
      <FormGroup error={getErrorMessageByFieldName("name")}>
        <Input
          error={getErrorMessageByFieldName("name")}
          value={name}
          onChange={handleNameChange}
          placeholder="Nome*"
          disabled={isSubmitting}
        />
      </FormGroup>
      <FormGroup error={getErrorMessageByFieldName("address")}>
        <Input
          error={getErrorMessageByFieldName("address")}
          value={address}
          onChange={handleAddressChange}
          placeholder="Endereço (ex: Rua Quinze, 12)*"
          disabled={isSubmitting}
        />
      </FormGroup>
      <FormGroup>
        <Input
          value={complement}
          onChange={(event) => setComplement(event.target.value)}
          placeholder="Complemento (ex: Bloco 1, apto 1120)"
          disabled={isSubmitting}
        />
      </FormGroup>

      <FormGroup isLoading={isLoadingCategories}>
        <Select
          value={categoryId}
          onChange={(event) => setCategoryId(event.target.value)}
          disabled={isLoadingCategories || isSubmitting}
        >
          <option value="">Sem categoria</option>
          {categories.map((category) => (
            <option key={category._id} value={category._id}>
              {category.name}
            </option>
          ))}
        </Select>
      </FormGroup>
      <ButtonContainer>
        <Button type="submit" disabled={!isFormValid} isLoading={isSubmitting}>
          {buttonLabel}
        </Button>
      </ButtonContainer>
    </Form>
  );
});
PackageForm.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
export default PackageForm;
