import { useEffect, useState, useRef, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";

import PageHeader from "../../components/PageHeader";
import PackageForm from "../../components/PackageForm";
import Loader from "../../components/Loader";
import PackagesService from "../../services/PackagesService";
import CategoriesService from "../../services/CategoriesService";
import toast from "../../utils/toast";
import useSafeAsyncAction from "../../hooks/useSafeAsyncAction";

import { AuthContext } from "../../contexts/auth";

export default function EditPackage() {
  const authContext = useContext(AuthContext);
  const token = authContext.user?.token;

  const { id } = useParams();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [packageName, setPackageName] = useState("");

  const packageFormRef = useRef(null);

  const safeAsyncAction = useSafeAsyncAction();

  useEffect(() => {
    async function loadPackage() {
      try {
        const idPackage = await PackagesService.getPackageById(id, token);

        safeAsyncAction(() => {
          packageFormRef.current.setFieldsValues(idPackage);
          setIsLoading(false);
          setPackageName(idPackage.name);
        });
      } catch {
        safeAsyncAction(() => {
          history.push("/");
          toast({
            type: "danger",
            text: "Encomenda não encontrada!",
          });
        });
      }
    }

    loadPackage();
  }, [id, history, safeAsyncAction, token]);

  async function handleSubmit(formData) {
    try {
      const categoryId = formData.categoryId;
      const categoryData = await CategoriesService.getCategoryById(
        categoryId,
        token
      );

      const extractedCategoryId = categoryData?._id ?? categoryId;

      const categoryPackage =
        categoryId !== ""
          ? {
              _id: extractedCategoryId,
              name: categoryData?.name,
            }
          : null;

      const myPackage = {
        package_number: formData.packageNumber,
        name: formData.name,
        address: formData.address,
        complement: formData.complement,
        category_package: categoryPackage,
      };

      await PackagesService.updatePackage(id, myPackage, token);

      setPackageName(formData.name);

      toast({
        type: "success",
        text: "Encomenda editada com sucesso!",
        duration: 3000,
      });
    } catch {
      toast({
        type: "danger",
        text: "Ocorreu um erro ao editar a encomenda",
        duration: 4000,
      });
    }
  }

  return (
    <>
      <Loader isLoading={isLoading} />

      <PageHeader
        title={isLoading ? "Carregando..." : `Editar ${packageName}`}
      />
      <PackageForm
        ref={packageFormRef}
        onSubmit={handleSubmit}
        buttonLabel="Salvar Alterações"
      />
    </>
  );
}
