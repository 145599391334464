// eslint-disable-next-line
export default {
  colors: {
    background: "#F6F5FC",

    primary: {
      lighter: "#e0e3ff",
      light: "#6674f4",
      main: "#5061FC",
      dark: "#3346f0",
    },

    danger: {
      light: "#f9717171",
      main: "#fc5050",
      dark: "#f63131",
    },

    success: {
      light: "#54D488",
      main: "#51ca73",
      dark: "#198d3b",
    },

    warning: {
      light: "#ffdab9",
      main: "#ffa500",
      dark: "#ff5400",
    },
  },

  gray: {
    900: "#222222",
    200: "#6b6a6a",
    100: "#e5e5e5",
  },
};
