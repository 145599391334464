import styled from "styled-components";

export const Container = styled.header`
  font-size: 12px;
  margin-top: 100px;
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
