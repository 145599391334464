import Httpclient from "./utils/Httpclient";
import urlDev from "./urlFetch";

class CategoriesService {
  constructor() {
    this.Httpclient = new Httpclient(urlDev);
  }

  listCategories(orderBy = "asc") {
    return this.Httpclient.get(`/categories?order=${orderBy}`);
  }

  getCategoryById(_id) {
    return this.Httpclient.get(`/categories/${_id}`);
  }
}

export default new CategoriesService();
