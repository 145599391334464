import { useState, useContext } from "react"; // Importe o useContext também
import LoginForm from "../../components/LoginForm";
import login from "../../assets/images/login.svg";
import { AuthContext } from "../../contexts/auth";

import { Container } from "./styles";

export default function SignIn() {
  const { signIn } = useContext(AuthContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleLoginSubmit = async (data) => {
    try {
      setIsSubmitting(true);
      await signIn(data.email, data.password);
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <img
        src={login}
        alt="login"
        style={{
          display: "block",
          margin: "25px auto",
          width: "100px",
          height: "auto",
        }}
      />
      <LoginForm
        buttonLabel="Fazer login"
        onSubmit={handleLoginSubmit}
        isLoading={isSubmitting}
      />
      <Container>
        <span>
          Ops...Não deveria estar aqui? Conheça a
          <a href="https://www.mypostal.com.br">
            <span className="my"> My</span>
            <span className="postal">Postal</span>&nbsp;
          </a>
        </span>
      </Container>
    </>
  );
}
