import React, { useState, createContext, useEffect } from "react";
import toast from "../utils/toast";
import { api } from "../services/apiClient";

export const AuthContext = createContext({});

function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function loadStorage() {
      const storageUser = localStorage.getItem("SistemaUser");

      if (storageUser) {
        setUser(JSON.parse(storageUser));
        setLoading(false);
      } else {
        setLoading(false);
      }
    }

    loadStorage();
  }, []);

  // Fazendo um login
  async function signIn(email, password) {
    setLoadingAuth(true);

    try {
      const response = await api.post("/users/login", { email, password });
      const data = response.data;

      setUser(data);
      storageUser(data);
      setLoadingAuth(false);

      toast({
        type: "success",
        text: "Login efetuado com sucesso!",
        duration: 3000,
      });
    } catch (error) {
      console.log(error);
      toast({
        type: "danger",
        text: "Usuário e/ou senha incorretos",
        duration: 4000,
      });
      setLoadingAuth(false);
    }
  }

  function storageUser(data) {
    localStorage.setItem("SistemaUser", JSON.stringify(data));
  }

  // Fazer logout de usuário
  function signOut() {
    localStorage.removeItem("SistemaUser");
    setUser(null);
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        loading,
        signOut,
        signIn,
        loadingAuth,
        storageUser,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
