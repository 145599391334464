import { useState } from "react";
import PropTypes from "prop-types";
import useErrors from "../../hooks/useErrors";

import isEmailValid from "../../utils/isEmailValid";

import { Form, ButtonContainer } from "./styles";

import FormGroup from "../FormGroup";
import Input from "../Input";
import Button from "../Button";

export default function SignIn({ buttonLabel, onSubmit }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  //
  const { errors, setError, removeError, getErrorMessageByFieldName } =
    useErrors();

  const isFormValid = email && password && errors.length === 0; // Verifica se ambos os campos estão preenchidos e não há erros

  function handleEmail(event) {
    setEmail(event.target.value);

    if (!event.target.value) {
      setError({
        field: "email",
        message: "E-mail é obrigatório",
      });
    } else if (!isEmailValid(event.target.value)) {
      setError({
        field: "email",
        message: "Informe um e-mail válido",
      });
    } else {
      removeError("email");
    }
  }

  function handlePassChange(event) {
    setPassword(event.target.value);

    if (!event.target.value) {
      setError({
        field: "password",
        message: "Senha é obrigatória",
      });
    } else {
      removeError("password");
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsSubmitting(true);

    await onSubmit({
      email,
      password,
    });

    setIsSubmitting(false);
  }
  return (
    <Form onSubmit={handleSubmit} noValidate>
      <FormGroup error={getErrorMessageByFieldName("email")}>
        <Input
          error={getErrorMessageByFieldName("email")}
          value={email}
          onChange={handleEmail}
          placeholder="E-mail*"
          disabled={isSubmitting}
        />
      </FormGroup>

      <FormGroup error={getErrorMessageByFieldName("password")}>
        <Input
          type={"password"}
          error={getErrorMessageByFieldName("password")}
          value={password}
          onChange={handlePassChange}
          placeholder="Senha*"
          disabled={isSubmitting}
        />
      </FormGroup>

      <ButtonContainer>
        <Button type="submit" disabled={!isFormValid} isLoading={isSubmitting}>
          {buttonLabel}
        </Button>
      </ButtonContainer>
    </Form>
  );
}

SignIn.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
