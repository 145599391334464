import styled from "styled-components";

export const Container = styled.header`
  margin-top: 75px;
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
