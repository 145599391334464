import Httpclient from "./utils/Httpclient";
import urlDev from "./urlFetch";

class PackagesService {
  constructor() {
    this.Httpclient = new Httpclient(urlDev);
  }

  listPackages(orderBy = "asc", token) {
    return this.Httpclient.get(`/manualpackages?order=${orderBy}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  getPackageById(id, token) {
    return this.Httpclient.get(`/manualpackages/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  createPackage(manualpackages, token) {
    return this.Httpclient.post("/manualpackages", {
      body: manualpackages,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  updatePackage(_id, manualpackages, token) {
    return this.Httpclient.put(`/manualpackages/${_id}`, {
      body: manualpackages,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  ConfirmPackageDelivery(_id, manualpackages, token) {
    const updatedPackage = {
      ...manualpackages,
      delivery: true,
    };

    return this.Httpclient.put(`/manualpackages/delivery/${_id}`, {
      body: updatedPackage,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export default new PackagesService();
