import React from "react";
import error from "../../assets/images/404.svg";
import PageHeader from "../../components/PageHeader";

import { EmptyListContainer } from "../Home/styles";

export default function NotFound() {
  return (
    <>
      <PageHeader />
      <EmptyListContainer>
        <img src={error} alt="Empty box" />
        <h2>Página não encontrada</h2>
        <h4>Volte ao menu principal</h4>
      </EmptyListContainer>
    </>
  );
}
